import React from "react";
import { defaultBlogImage } from "../../assets/images";
import { useNavigate } from "react-router-dom";

type Props = {
  blogInfo: {
    title: string;
    desc: string;
    _id: string;
    imageSrc?: string;
    createdAt: string;
  };
};

const BlogCard: React.FC<Props> = ({ blogInfo }) => {
  const navigate = useNavigate();

  return (
    <div
      className="text-left ml-4 mr-4"
      style={{ maxWidth: 840 }}
      onClick={() => {
        navigate(`/blogs/${blogInfo._id}`, { state: { blogInfo } });
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }}
    >
      <div className="relative">
        <img
          src={blogInfo.imageSrc || defaultBlogImage}
          alt="Blog Display Pic"
          className="mb-4 h-32 md:h-48 sm:h-56 w-full object-cover"
        />
        <div
          className="absolute bg-orange-500 p-2 pl-4 pr-4 right-2 rounded-full"
          style={{ bottom: -16 }}
        >
          <text className="text-white font-sans">
            {new Date(blogInfo.createdAt).toString().slice(0, 16)}
          </text>
        </div>
      </div>
      <div className="ml-2 hover:text-orange-500">
        <text className="text-xl font-semibold font-serif">
          {blogInfo.title}
        </text>
      </div>
    </div>
  );
};

export default BlogCard;
