import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { underlineImage } from "../../assets/images";

let selectedTestimonialChangeIntervalRef: any;

const TestimonialsSection: React.FC = () => {
  const [selectedTestimonialId, setSelectedTestimonialId] =
    React.useState<string>("vineetaLakra");

  const testimonials: {
    [key: string]: {
      text: string;
      name: string;
      bgColor: string;
      textColor: string;
    };
  } = useMemo(
    () => ({
      vineetaLakra: {
        text: "This was my first time to speak to any Astrologer and I must say i was satisfied with the experience. There were certain things which i might have hardly shared to anyone but he was correct to the point on whatever he described about me. So do give it a chance  and have a word with him I am sure it will surely help you in some or the other way.🌟",
        name: "Vineeta Lakra",
        bgColor: "bg-orange-100",
        textColor: "text-orange-500",
      },
      prasadTvs: {
        text: "Satish sir is very patient and professional. We were confused with the next step and he has immensely helped us in making a decision on the basis of astrology. Would definitely recommend him for all astrological needs.",
        name: "Prasad Tvs",
        bgColor: "bg-orange-100",
        textColor: "text-orange-500",
      },
      madhurV: {
        text: "He's very patient and gives enough time to explain everything in detail. He's an educated and honest person, and I'll definitely consult him for my future horoscope readings.",
        name: "Madhur V",
        bgColor: "bg-orange-100",
        textColor: "text-orange-500",
      },
      kotturtiDeviPrasad: {
        text: "His sayings are good and my experience with him is almost 99% accurate as per my experience. Any type of consultations regarding astrology, Dwarkaa astrology is the right place.",
        name: "Kotturti Devi Prasad",
        bgColor: "bg-orange-100",
        textColor: "text-orange-500",
      },
      deelakshaChandrashekar: {
        text: "Felt really great after talking with Satheesh guruji. Very informative and true facts. Positive energy and blessings.",
        name: "Deelaksha chandrashekar",
        bgColor: "bg-orange-100",
        textColor: "text-orange-500",
      },
      pragyanParamita: {
        text: "Satish Astrologer is a very good astrologer where all his prediction comes out true and his remedies really work well. He reads the horoscope very minutely and my trust on him always gets more and more as because whatever he predicted for me till now, everything has happened. Even during my bad times, his remedies has helped me and my family come out if it.",
        name: "Pragyan Paramita",
        bgColor: "bg-orange-100",
        textColor: "text-orange-500",
      },
    }),
    []
  );

  const changeTestimonial = useCallback(() => {
    const testimonialIds = Object.keys(testimonials);
    const randomIndex = Math.floor(Math.random() * testimonialIds.length);
    const randomTestimonialId = testimonialIds[randomIndex];
    setSelectedTestimonialId(randomTestimonialId);
  }, [testimonials]);

  useEffect(() => {
    selectedTestimonialChangeIntervalRef = setInterval(() => {
      changeTestimonial();
    }, 5000);
    return () => clearInterval(selectedTestimonialChangeIntervalRef);
  }, [changeTestimonial]);

  const onSelectTestimonial = (testimonialId: string) => {
    setSelectedTestimonialId(testimonialId);
    clearInterval(selectedTestimonialChangeIntervalRef);
  };

  return (
    <div className="flex-1 justify-center text-center md:pl-48 md:pr-48 lg:pl-68 lg:pr-68 pl-8 pr-8 md:pt-16 md:pb-8 pt-8 pb-8">
      <text className="font-serif font-medium" style={{ fontSize: 32 }}>
        What Our Customers Say
      </text>
      <div className="mb-4 flex justify-center">
        <img src={underlineImage} alt="Underline" />
      </div>
      <div>
        <text className="font-light" style={{ fontSize: 14 }}>
          We pride ourselves on delivering the best possible service to our
          customers. Don't just take our word for it - read what our satisfied
          customers have to say about their experiences.
        </text>
      </div>
      <div className="md:flex md:mt-12 mt-8">
        <div className="md:w-1/3 flex md:mr-4 md:mt-0 md:mb-0 mt-12 mb-12 justify-center items-center">
          <text
            className="font-extrabold text-4xl text-orange-400 mr-4"
            onClick={changeTestimonial}
          >
            {"<"}
          </text>
          <div
            onClick={() => onSelectTestimonial(selectedTestimonialId)}
            className={`${"w-20 h-20 rounded-full flex justify-center items-center text-2xl font-bold drop-shadow-2xl"} ${
              testimonials[selectedTestimonialId].bgColor
            }`}
          >
            <text
              className={`${testimonials[selectedTestimonialId].textColor}`}
            >
              {testimonials[selectedTestimonialId].name[0]}
            </text>
          </div>
          <text
            className="font-extrabold text-4xl text-orange-400 ml-4"
            onClick={changeTestimonial}
          >
            {">"}
          </text>
        </div>
        <div className="bg-slate-200 md:w-0.5" />
        <div className="md:w-2/3 items-center justify-center">
          <div className="testimonial-container p-8 md:ml-12 rounded-3xl">
            <div className="testimonial-container-bg-image-container">
              <div className="testimonial-container-bg-image" />
            </div>
            <div style={{ zIndex: 10 }}>
              <text className="text-white">
                {testimonials[selectedTestimonialId].text}
              </text>
              <div className="mt-4">
                <text className="text-white font-bold">
                  {`- ${testimonials[selectedTestimonialId].name}`}
                </text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Link
          to="https://www.google.com/maps/place/Dwarakaa+Astrology/@17.5760389,78.3972811,12z/data=!4m18!1m9!3m8!1s0x3bcb8d8ad2bb3dc9:0x1ab1c908f9b3c3d7!2sDwarakaa+Astrology!8m2!3d17.5487302!4d78.3853136!9m1!1b1!16s%2Fg%2F11fsjzkh61!3m7!1s0x3bcb8d8ad2bb3dc9:0x1ab1c908f9b3c3d7!8m2!3d17.5487302!4d78.3853136!9m1!1b1!16s%2Fg%2F11fsjzkh61"
          target="_blank"
        >
          <text className="font-serif text-lg">
            <text className="text-orange-500 font-bold">Rate us</text> on Google
          </text>
        </Link>
      </div>
    </div>
  );
};

export default TestimonialsSection;
