import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LoginPopup,
  PaymentSuccessPopup,
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { ServicesType } from "../../utils/types";
import parse from "html-react-parser";
import {
  consultationServicedefaultImage,
  ritualServicedefaultImage,
} from "../../assets/images";
import Cookies from "js-cookie";
import {
  checkIfIndianUser,
  sendMailToRequesService,
} from "../../utils/utilityMethods";

const SingleServiceScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [serviceInfo, setServiceInfo] = React.useState<ServicesType | null>(
    location.state?.serviceInfo || null
  );
  const [openLoginPopUp, setOpenLoginPopUp] = React.useState<boolean>(false);
  const [paymentSuccessInfo, setPaymentSuccessInfo] = React.useState<{
    razorpay_order_id: string;
    razorpay_payment_id: string;
    razorpay_signature: string;
    price: {
      amount: number;
      currency: string;
    };
  } | null>(null);

  const isIndianUser = checkIfIndianUser();

  useEffect(() => {
    if (!location.state?.serviceInfo) {
      //fetch service info
    }
  }, [location.state]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onPaymentSucces = (response: any) => {
    setPaymentSuccessInfo(response);
  };

  const onPaymentFailed = (response: any) => {
    alert("Payment Failed! Please try again");
  };

  const handleClickOnBuyNow = (event: any) => {
    const authToken = Cookies.get("jwt-token");
    const userInfo = JSON.parse(Cookies.get("userInfo") || "{}");
    if (!authToken) {
      setOpenLoginPopUp(true);
      return;
    }
    fetch(`${process.env.REACT_APP_BASE_URL}order/createOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
      body: JSON.stringify({
        amount:
          (serviceInfo?.price.find((price) => price.currency === "INR")
            ?.amount || 0) * 100,
        currency: "INR",
        env: process.env.REACT_APP_ENV,
        userId: userInfo._id,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        const { keyId, amount, currency, order_id } = data.res;
        var options = {
          key: keyId,
          amount: amount,
          currency: currency,
          name: "Dwaraka Astrology",
          description: serviceInfo?.name,
          order_id: order_id,
          prefill: {
            name: userInfo.name,
            email: userInfo.email,
            contact: userInfo.phone,
          },
          theme: {
            color: "#3399cc",
          },
          handler: (res: any) =>
            onPaymentSucces({
              ...res,
              price: { amount: amount, currency: currency },
            }),
        };
        if ((window as any)?.Razorpay) {
          const instance = new (window as any).Razorpay(options);
          instance.on("payment.failed", onPaymentFailed);
          instance.open();
          event.preventDefault();
        } else {
          console.log("no instance");
        }
      })
      .catch((err) => {
        alert("Something went wrong! Please try again");
      });
  };

  const handleClickOnSendEnquiry = () => {
    const authToken = Cookies.get("jwt-token");
    const userInfo = Cookies.get("userInfo");
    if (!!authToken) {
      sendMailToRequesService({
        userInfo: JSON.parse(userInfo || "") as any,
        serviceInfo: serviceInfo as ServicesType,
      });
    } else {
      setOpenLoginPopUp(true);
    }
  };

  const renderPaymentSuccess = useMemo(() => {
    if (!paymentSuccessInfo) return null;
    return (
      <PaymentSuccessPopup
        isVisible={!!paymentSuccessInfo}
        paymentInfo={paymentSuccessInfo}
        serviceAvailedInfo={{
          category: serviceInfo?.category || "",
          name: serviceInfo?.name || "",
          code: serviceInfo?.code || "",
          desc: serviceInfo?.desc || "",
        }}
        onClose={() => {
          setPaymentSuccessInfo(null);
        }}
      />
    );
  }, [paymentSuccessInfo, serviceInfo]);

  return (
    <>
      <LoginPopup
        isVisible={openLoginPopUp}
        onClose={() => {
          setOpenLoginPopUp(false);
        }}
      />
      <ScreenHeader />
      {window.innerWidth > 550 && (
        <ScreenTopSection
          text={serviceInfo?.name.split("(")[0] || "Not Found"}
          navigationStack={[
            { text: "Home", onClick: () => navigate("/") },
            { text: "Services", onClick: () => navigate("/blogs") },
            {
              text: serviceInfo?.name.split("(")?.[0] || "Not Found",
              onClick: () => null,
            },
          ]}
        />
      )}
      <div className="pb-24 pt-16 flex flex-wrap md:pl-32 md:pr-32 pl-8 pr-8 service-container">
        <div className="md:flex mb-8">
          <div className="md:w-2/5 w-full">
            <img
              src={
                serviceInfo?.category === "Consultation"
                  ? consultationServicedefaultImage
                  : require(`../../assets/images/Rituals/${
                      serviceInfo?.code || "SAI-RO1"
                    }.jpeg`)
              }
              alt="ServiceImage"
            />
          </div>
          <div className="md:w-3/5 w-full flex flex-col pr-8 md:pl-8 md:mt-0 mt-6">
            <text className="font-serif text-2xl font-semibold">
              {serviceInfo?.name}
            </text>
            <text className="text-gray-500 mt-1">{`(${serviceInfo?.category})`}</text>
            <text className="mt-4 text-orange-600 font-bold text-xl">{`₹ ${
              serviceInfo?.price.find((price) => price.currency === "INR")
                ?.amount
            }`}</text>
            {isIndianUser ? (
              <button
                className="mt-4 self-start bg-orange-600 p-2 pr-8 pl-8 border-2 rounded-md hover:rounded-sm text-white hover:text-orange-600 hover:bg-white hover:border-orange-600 border-transparent shadow-lg font-semibold"
                onClick={handleClickOnBuyNow}
              >
                {"Buy Now"}
              </button>
            ) : (
              <button
                className="mt-4 self-start bg-orange-600 p-2 pr-8 pl-8 border-2 rounded-md hover:rounded-sm text-white hover:text-orange-600 hover:bg-white hover:border-orange-600 border-transparent shadow-lg font-semibold"
                onClick={handleClickOnSendEnquiry}
              >
                {"Send Enquiry"}
              </button>
            )}
          </div>
        </div>
        <div className="mb-16">{parse(serviceInfo?.desc || "")}</div>
      </div>
      <ScreenFooter />
      {renderPaymentSuccess}
    </>
  );
};

export default SingleServiceScreen;
