import React from "react";
import "../../App.css";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
  TestimonialsSection,
} from "../../components/compoundComponents";
import { useNavigate } from "react-router-dom";
import { astrologerImage } from "../../assets/images";

const AboutScreen: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="About">
      <ScreenHeader selectedSectionId="about" />
      <ScreenTopSection
        text="About Me"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "About Me", onClick: () => navigate("/about") },
        ]}
      />
      <div className="pt-16 homepage-bgAboutUs bg-repeat">
        <div className="md:justify-evenly justify-center items-center flex sm:pl-12 sm:pr-12 pl-8 pr-8 flex-wrap">
          <img
            src={astrologerImage}
            alt="AstrologerImage"
            className="sm:h-100 h-80 md:w-1/3 object-contain"
          />
          <div className="md:w-1/2 md:text-left text-center mt-8 md:mt-0">
            <div>
              <text
                className="font-semibold font-serif"
                style={{ fontSize: 24 }}
              >
                Vedic Astrologer in India
              </text>
            </div>
            <div className="mt-2">
              <text className="font-serif">
                I am someone like you who started searching for the proper
                direction of my life. I witnessed that so many people are
                struggling to get a good career, money, health, happiness, love
                and so on, but few people are not struggling for anything as
                they are already blessed with all the best things in life and
                yes that's the turning point of my life and there my journey
                started with many questions to be answered and to explore when
                this universe is the same for everyone then why are these
                differences?
                <br />
                <br /> I strongly felt that there is something which is ruling
                our lives, and my destiny strongly pushed me to learn, research,
                explore, and think about Vedic Astrology. I never thought of
                becoming an Astrologer, my life is a real example of how destiny
                can take charge of our life and show its results.
                <br />
                <br /> My journey towards the astrology field made me believe
                the statement -{" "}
                <b>
                  “No one can choose Astrology, Astrology only chooses you”.
                </b>{" "}
                Now, I truly feel that I am blessed to be here.
              </text>
            </div>
          </div>
        </div>
        <div className="mt-12 md:pl-32 md:pr-12 pl-8 pr-8 md:text-left text-center">
          <div>
            <text className="font-serif font-semibold" style={{ fontSize: 18 }}>
              What is so unique about Vedic Astrology?
            </text>
          </div>
          <div className="mt-2">
            <text className="font-serif">
              The best part about Vedic Astrology is that it provides remedies
              for all bad placements of the planets. The remedies that I will
              tell you are nothing like what most Vedic astrological websites
              tell you. Gemstones, donations, feeding animals, yantras, nothing
              of that sort I support right now, not until it's scientifically
              proven that it helps you and makes a difference. What has worked
              for me, I am sharing that with you on this website.
              <br />
              <br /> My predictions and remedies are driven by science that can
              offer guidance and insights into a person's life path, strengths,
              weaknesses, and potential challenges. My goal is to add a catalyst
              to your life that can help you in gaining greater self-awareness,
              personal growth and provide guidance in navigating life's ups &
              downs and help you see what you cannot see.
            </text>
          </div>
          <div className="mt-12">
            <text className="font-serif font-semibold" style={{ fontSize: 20 }}>
              About Me
            </text>
          </div>
          <div className="mt-2">
            <text className="font-serif mt-12">
              I am a{" "}
              <b>
                Certified Indian Vedic Astrologer, Gem & Rudraksha Consultant
                and Vastu Shastra expert.
              </b>{" "}
              I am an expert in handling various challenges of my clients across
              the world and providing them with the appropriate solutions. I was
              fascinated by the mysterious nature of astrology and this
              compelled me to undergo a professional course in Vedic Astrology.
              I have been doing research with live horoscopes on various
              challenges faced by people. As my predictions started coming true
              for most of the people, I became a full-time Vedic astrologer and
              have been serving clients from all over the country.
            </text>
          </div>
        </div>
        <div className="flex justify-center mt-16 pb-8 pl-16 pr-16">
          <text
            className="font-semibold font-serif italic text-center"
            style={{ fontSize: 20 }}
          >
            “Explore the possibilities and accelerate your path to prosperity.”
          </text>
        </div>
      </div>
      <TestimonialsSection />
      <ScreenFooter />
    </div>
  );
};

export default AboutScreen;
