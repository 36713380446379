import React, { useEffect, useState } from "react";
import RootNavigation from "./navigation/RootNavigator";

const App: React.FC = () => {
  const [showScrollToTopFAB, setShowScrollToTopFAB] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.pageYOffset > 20) setShowScrollToTopFAB(true);
      else setShowScrollToTopFAB(false);
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  });
  return (
    <>
      {showScrollToTopFAB && (
        <button
          onClick={() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}
          className="fixed z-90 bottom-10 right-8 bg-orange-600 w-12 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-2xl hover:drop-shadow-2xl z-50"
        >
          &#8593;
        </button>
      )}
      <RootNavigation />
    </>
  );
};

export default App;
