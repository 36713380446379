import React, { useCallback, useEffect, useMemo } from "react";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

type ServiceRequest = {
  orderId: string;
  paymentId: string;
  price: {
    currency: string;
    amount: number;
  };
  serviceAvailedInfo: {
    category: string;
    name: string;
    code: string;
    desc: string;
  };
  userId: string;
  purchasedAt: Date;
  serviceCompleted: boolean;
};

type PendingTransaction = {
  id: string;
  amount: number;
  amount_paid: number;
  amount_due: number;
  currency: string;
  status: "pending";
  created_at: Date;
};

const demoPendingTransactions: PendingTransaction[] = [
  {
    id: "asdfasd0",
    amount: 1000,
    amount_paid: 1000,
    amount_due: 0,
    currency: "INR",
    status: "pending",
    created_at: new Date(),
  },
  {
    id: "asdfasd1",
    amount: 1000,
    amount_paid: 1000,
    amount_due: 0,
    currency: "INR",
    status: "pending",
    created_at: new Date(),
  },
  {
    id: "asdfasd2",
    amount: 1000,
    amount_paid: 1000,
    amount_due: 0,
    currency: "INR",
    status: "pending",
    created_at: new Date(),
  },
];

const demoServiceRequestList = [
  {
    orderId: "fads",
    paymentId: "",
    price: {
      currency: "INR",
      amount: 1000,
    },
    serviceAvailedInfo: {
      category: "Consultation",
      name: "Service Name 1",
      code: "Service Code 1",
      desc: "Service Description 1",
    },
    userId: "sdfaasdfasdfad",
    purchasedAt: new Date(),
    serviceCompleted: false,
  },
  {
    orderId: "ert",
    paymentId: "",
    price: {
      currency: "INR",
      amount: 1030,
    },
    serviceAvailedInfo: {
      category: "Consultation",
      name: "Service Name 2",
      code: "Service Code 2",
      desc: "Service Description 2",
    },
    userId: "sdfaasdfasdfad",
    purchasedAt: new Date(),
    serviceCompleted: true,
  },
  {
    orderId: "fgdhj",
    paymentId: "",
    price: {
      currency: "INR",
      amount: 5000,
    },
    serviceAvailedInfo: {
      category: "Consultation",
      name: "Service Name 3",
      code: "Service Code 3",
      desc: "Service Description 3",
    },
    userId: "sdfaasdfasdfad",
    purchasedAt: new Date(),
    serviceCompleted: false,
  },
];

const UserAccount: React.FC = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userInfo") || "{}");

  const [isApiLoading, setApiLoading] = React.useState(true);
  const [serviceRequestList, setServiceRequestList] = React.useState<
    ServiceRequest[]
  >([]);
  const [pendingTransactions, setPendingTransactions] = React.useState<
    PendingTransaction[]
  >([]);
  const [
    isPendingTransactionsModalVisible,
    setPendingTransactionsModalVisible,
  ] = React.useState(false);

  const getServiceRequestListAndPendingTransactions = useCallback(async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}service-request/listPending?env=${process.env.REACT_APP_ENV}&userId=${userInfo._id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        console.log(data, "data");
        setServiceRequestList(data?.res?.result || []);
        setPendingTransactions([]);
        setApiLoading(false);
      })
      .catch((err) => {
        alert("Something went wrong. Please try again later!");
        setApiLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServiceRequestListAndPendingTransactions();
  }, [getServiceRequestListAndPendingTransactions]);

  const refreshTransactionStatus = useCallback(() => {
    // TODO: need to work on it
    getServiceRequestListAndPendingTransactions();
  }, [getServiceRequestListAndPendingTransactions]);

  const renderPendingTransactionsModal = useMemo(() => {
    if (isPendingTransactionsModalVisible) {
      return (
        <>
          <div
            className="fixed bg-white flex h-screen w-5/6 sm:w-1/2 md:w-1/3"
            style={{ zIndex: 10000 }}
          >
            {!!pendingTransactions.length ? (
              <div>
                <div
                  onClick={() => {}}
                  className={
                    "sm:p-4 sm:pl-8 sm:pr-8 p-4 border-b-4 border-orange-600"
                  }
                >
                  <text className={"sm:text-2xl font-serif text-orange-600"}>
                    {"Pending Orders"}
                  </text>
                </div>
                <></>
              </div>
            ) : (
              <div className="text-center p-4 flex-1 justify-center items-center self-center">
                <text className="text-orange-600 text-xl md:text-2xl font-semibold">
                  No Pending Orders
                </text>
                <br />
                <button
                  onClick={() => {
                    setPendingTransactionsModalVisible(false);
                  }}
                  className="mt-8 bg-orange-600 p-4 pl-8 pr-8 rounded-md text-white font-bold hover:bg-orange-100 hover:text-orange-600"
                >
                  Go Back
                </button>
              </div>
            )}
          </div>
          <div
            className="overlay"
            onClick={() => setPendingTransactionsModalVisible(false)}
          />
        </>
      );
    }
  }, [isPendingTransactionsModalVisible, pendingTransactions]);

  const renderEmptyServiceRequestList = useMemo(() => {
    if (isApiLoading) {
      return (
        <div className="flex justify-center">
          <div className="loading-spinner" />
        </div>
      );
    }
    return (
      <div className="text-center p-4">
        <text className="text-orange-600 text-xl md:text-2xl font-semibold">
          No Services Requested
        </text>
        <br />
        <text className="text-gray-400 font-medium text-sm md:text-base">
          You can request for our services from the services page
        </text>
        <br />
        <button
          onClick={() => {
            navigate("/services");
          }}
          className="mt-8 bg-orange-600 p-4 pl-8 pr-8 rounded-md text-white font-bold hover:bg-orange-100 hover:text-orange-600"
        >
          Go to Services
        </button>
      </div>
    );
  }, [navigate, isApiLoading]);

  const renderPurchaseAndOrderHistory = useMemo(() => {
    return (
      <div className="md:mt-12 mt-8">
        <div
          className={
            "sm:p-4 sm:pl-8 sm:pr-8 p-4 border-b-4 border-orange-600 flex flex-row justify-between items-center flex-wrap-reverse"
          }
        >
          <div>
            <text className={"sm:text-2xl font-serif text-orange-600"}>
              {"Service Requests"}
            </text>
          </div>
          <div
            onClick={() => {
              setPendingTransactionsModalVisible(true);
            }}
          >
            <text
              className={
                "font-serif text-orange-600 flex flex-row underline text-xs sm:text-sm md:text-base"
              }
            >
              {"Pending Orders"}
              <div className="ml-1">&#9201;</div>
            </text>
          </div>
        </div>
        <div className="shadow-lg rounded-b-lg md:p-12 sm:p-8 p-4 max-h-96 overflow-auto">
          {!!serviceRequestList.length ? (
            <>
              {serviceRequestList.map((serviceRequest) => {
                return (
                  <div key={serviceRequest.orderId}>
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-col flex-1">
                        <div className="font-medium sm:text-lg md:text-xl text-base">
                          <text>{serviceRequest.serviceAvailedInfo.name}</text>
                        </div>
                        <div className="text-xs sm:text-sm md:text-base text-gray-500">
                          <text>{"Date:" + serviceRequest.purchasedAt}</text>
                        </div>
                      </div>
                      <div className="self-end flex flex-col items-end ml-4">
                        <div className="font-medium sm:text-lg md:text-xl text-base">
                          <text>{serviceRequest.price.amount / 100}</text>
                        </div>
                        <div className="flex-row flex items-center">
                          {serviceRequest.serviceCompleted ? (
                            <div className={"text-green-800 mr-1"}>
                              &#10003;
                            </div>
                          ) : (
                            <div className={"text-amber-800 mr-1"}>&#9201;</div>
                          )}
                          <text
                            className={`text-xs sm:text-sm md:text-base ${
                              !serviceRequest.serviceCompleted
                                ? "text-amber-800"
                                : "text-green-800"
                            }`}
                          >
                            {serviceRequest.serviceCompleted
                              ? "Completed"
                              : "Pending"}
                          </text>
                        </div>
                      </div>
                    </div>
                    <div className="border-b-2 border-gray-200 mt-4 mb-4" />
                  </div>
                );
              })}
            </>
          ) : (
            renderEmptyServiceRequestList
          )}
        </div>
      </div>
    );
  }, [serviceRequestList, renderEmptyServiceRequestList]);

  return (
    <>
      {renderPendingTransactionsModal}
      <ScreenHeader />
      <ScreenTopSection
        text={"My Profile"}
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Profile", onClick: () => null },
        ]}
      />
      {userInfo ? (
        <div className="mr-8 ml-8 sm:mr-16 sm:ml-16 md:mr-32 md:ml-32 mt-16 mb-32 lg:mr-48 lg:ml-48 sm:border-2 sm:border-b-0 sm:shadow sm:shadow-gray-600 sm:rounded-lg sm:p-12">
          <div className="md:flex md:items-center items-center justify-center md:justify-start">
            <div className="justify-center flex">
              <div className="relative md:w-32 md:h-32 w-24 h-24 overflow-hidden bg-orange-600 rounded-full">
                <svg
                  className="absolute md:w-36 md:h-36 w-28 h-28 text-white -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="flex-col md:text-left text-center md:ml-8 md:mt-0 mt-4">
              <div>
                <text className="md:text-3xl text-xl font-serif font-semibold">
                  {userInfo.name}
                </text>
              </div>
              <div>
                <text className="md:text-sm text-xs text-gray-500">
                  {"Phone: " + userInfo.phone}
                </text>
              </div>
              <div>
                <text className="font-medium text-sm md:text-base">
                  {userInfo.email}
                </text>
              </div>
            </div>
          </div>
          {renderPurchaseAndOrderHistory}
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="loading-spinner" />{" "}
        </div>
      )}
      <ScreenFooter />
    </>
  );
};

export default UserAccount;
