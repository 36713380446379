import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { defaultBlogImage } from "../../assets/images";
import parse from "html-react-parser";

const SingleBlogScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [blogInfo, setBlogInfo] = React.useState<{
    title: string;
    desc: string;
    _id: string;
    imageSrc?: string;
  } | null>(location.state?.blogInfo || null);

  useEffect(() => {
    if (!location.state?.blogInfo) {
      //fetch blog info
    }
  }, [location.state]);

  return (
    <>
      <ScreenHeader />
      <ScreenTopSection
        text={blogInfo?.title || "Not Found"}
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Blog", onClick: () => navigate("/blogs") },
          { text: blogInfo?.title || "Not Found", onClick: () => null },
        ]}
      />
      <div className="pb-24 pt-16 flex flex-wrap md:pl-32 md:pr-32 pl-8 pr-8">
        <div className="md:w-3/4 mb-16">
          <img
            src={blogInfo?.imageSrc || defaultBlogImage}
            alt="Blog Display Pic"
            className="mb-4 md:h-64 h-48 md:w-2/3 w-full sm:h-56 object-cover"
          />
          {parse(blogInfo?.desc || "")}
        </div>
        <div
          className="md:w-1/4 bg-orange-50 self-start p-4 md:pl-8"
          style={{ borderRadius: 4 }}
        >
          <text className="font-serif font-semibold text-lg">
            {"Recent Blogs"}
          </text>
          <div className="bg-orange-500 w-1/3 mt-1" style={{ height: 2 }} />
          <div className="mt-4 text-center">
            <text className="italic">Coming Soon</text>
          </div>
        </div>
      </div>
      <ScreenFooter />
    </>
  );
};

export default SingleBlogScreen;
