import React, { useCallback, useRef } from "react";
import { appLogo } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

type Props = {
  isVisible: boolean;
  onClose: () => void;
};
const LoginPopup: React.FC<Props> = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  const formDetails = useRef<{
    email?: string;
    password?: string;
  }>({});
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const loginUser = useCallback(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
      body: JSON.stringify({
        email: formDetails.current.email,
        password: formDetails.current.password,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.status === 1) {
          alert("Logged in successfully!");
          onClose?.();
          Cookies.set("jwt-token", data.res.accessToken);
          Cookies.set("userInfo", JSON.stringify(data.res.user));
          window.location.reload();
        } else if (data.err.errCode === 401) {
          alert(
            "Wrong email or password! Please check your credentials and try again"
          );
          passwordInputRef.current!.value = "";
        }
      })
      .catch((err) => {
        alert("Something went wrong! Please try again");
        passwordInputRef.current!.value = "";
      });
  }, [onClose]);

  const onChangeValue = useCallback((inputId: string, value: string) => {
    formDetails.current = {
      ...formDetails.current,
      [inputId]: value,
    };
  }, []);

  const renderInput = useCallback(
    ({
      id,
      label,
      type,
      placeholder,
      inputRef,
    }: {
      id: string;
      label: string;
      type: "text" | "password";
      placeholder: string;
      inputRef: React.RefObject<HTMLInputElement> | null;
    }) => {
      return (
        <div className="mt-6">
          <div className="mb-2">
            <text className="text-base font-semibold">{label}</text>
          </div>
          <input
            id={id}
            ref={inputRef}
            type={type}
            placeholder={placeholder}
            className="w-full h-12 pl-4 pr-4 border-2 rounded-md border-gray-300 focus:outline-none focus:border-orange-600"
            onChange={(e) => onChangeValue(id, e.target.value)}
          />
        </div>
      );
    },
    [onChangeValue]
  );

  const renderLoginPopUp = () => {
    return (
      <div
        className={`bg-white flex flex-col p-8 pb-12 sm:w-2/3 md:w-1/2 w-3/4 ${
          window.innerWidth > 600
            ? "popup rounded-lg"
            : "bottom-popup rounded-t-lg"
        }`}
      >
        <img src={appLogo} className="w-64 self-center" alt="appLogo" />
        <text className="text-center text-lg mt-4 text-gray-500">
          Please enter your details to signin.
        </text>
        {renderInput({
          id: "email",
          label: "Email",
          type: "text",
          placeholder: "Enter your email",
          inputRef: emailInputRef,
        })}
        {renderInput({
          id: "password",
          label: "Password",
          placeholder: "********",
          type: "password",
          inputRef: passwordInputRef,
        })}
        <button
          className="mt-8 p-2 bg-orange-600 rounded-md hover:rounded-sm text-white hover:bg-orange-700 shadow-lg font-semibold"
          onClick={loginUser}
        >
          Log In
        </button>
        <text className="text-gray-600 text-center mt-3">
          Don't have an account?{" "}
          <text
            className="underline text-black font-semibold"
            onClick={() => {
              onClose?.();
              navigate("/account/register");
            }}
          >
            Create account
          </text>
        </text>
      </div>
    );
  };

  const renderOverlay = () => {
    return <div className="overlay" onClick={onClose}></div>;
  };

  return (
    <>
      {isVisible && renderLoginPopUp()}
      {isVisible && renderOverlay()}
    </>
  );
};

export default LoginPopup;
