import React, { useMemo } from "react";
import {
  chakraBackground,
  saiBabaImage,
  astrologerImage,
  underlineImage,
} from "../../assets/images";
import {
  BlogsSection,
  ScreenFooter,
  ScreenHeader,
  TestimonialsSection,
} from "../../components/compoundComponents";
import "./home.css";
import { useNavigate } from "react-router-dom";

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();

  const homeTopSection = useMemo(() => {
    return (
      <div className="homepage-bgimage pt-16 pb-32 bg-cover bg-bottom">
        <div className=" flex flex-wrap-reverse justify-center items-center">
          <div className="md:w-1/2 justify-center items-center">
            <div className="md:ml-24 sm:ml-16 sm:mr-16 ml-4 mr-4 md:mt-0 mt-8 md:text-left text-center">
              <text style={{ fontSize: 32 }} className="text-white">
                Uncover your cosmic destiny with astrology
              </text>
            </div>
            <div className="md:ml-24 sm:ml-16 sm:mr-16 ml-4 mr-4 mt-4 md:text-left text-center">
              <text style={{ fontSize: 16 }} className="text-white">
                Experience the transformative power of astrology and awaken your
                true potential with comprehensive range of astrological
                services.
              </text>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-center items-center">
            <img
              src={chakraBackground}
              alt="ChakraBackground"
              className="absolute animate-spin-slow origin-center md:w-80 md:h-80 w-64 h-64 lg:w-96 lg:h-96"
            />
            <img
              src={saiBabaImage}
              alt="SaiBabaImage"
              className="md:w-80 md:h-80 w-64 h-64 lg:w-96 lg:h-96 z-10"
            />
          </div>
        </div>
      </div>
    );
  }, []);

  const aboutUsSection = useMemo(() => {
    return (
      <div className="homepage-bgAboutUs sm:p-12 p-8 bg-cover justify-center items-center bg-top">
        <div className="text-center md:pl-24 md:pr-24 sm:pl-8 sm:pr-8">
          <text className="font-medium" style={{ fontSize: 32 }}>
            About Astrologer
          </text>
          <div className="mb-4 flex justify-center">
            <img src={underlineImage} alt="Underline" />
          </div>
          <text className="font-light w-0" style={{ fontSize: 14 }}>
            There is something which is ruling our lives, and my destiny
            strongly pushed me to learn, research, explore, and think about
            Vedic Astrology. I never thought of becoming an Astrologer, my life
            is a real example of how destiny can take charge of our life and
            show its results.
          </text>
        </div>
        <div className="md:justify-evenly justify-center items-center flex mt-12 sm:pl-12 sm:pr-12 flex-wrap">
          <img
            src={astrologerImage}
            alt="AstrologerImage"
            className="h-80 md:w-1/3 object-contain"
          />
          <div className="md:w-1/2 md:text-left text-center mt-8 md:mt-0">
            <text className="font-serif">
              I am a certified Indian Vedic Astrologer, Gem & Rudraksha
              Consultant and Vastu Shastra expert. I am an expert in handling
              various challenges of my clients across the world and providing
              them with the appropriate solutions. I was fascinated by the
              mysterious nature of astrology and this compelled me to undergo a
              professional course on various challenges faced by people. <br />
              <br /> As my predictions started in Vedic Astrology. I have been
              doing research with live horoscopes coming true for most of the
              people, I became a full-time Vedic astrologer and have been
              serving clients from all over the country.
            </text>
            <div className="md:justify-start justify-center flex">
              <div
                className="bg-orange-600 p-1 pl-2 pr-2 mt-6"
                onClick={() => {
                  navigate("/about");
                  window.scroll({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                <text className="text-white">{"Know More >>"}</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [navigate]);

  return (
    <div>
      <ScreenHeader selectedSectionId="home" />
      {homeTopSection}
      <BlogsSection />
      {aboutUsSection}
      <TestimonialsSection />
      <ScreenFooter />
    </div>
  );
};

export default HomeScreen;
