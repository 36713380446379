import React from "react";
import "./compoundComponents.css";

type Props = {
  text: string;
  navigationStack: {
    text: string;
    onClick: () => void;
  }[];
};

const ScreenTopSection: React.FC<Props> = ({ text, navigationStack }) => {
  return (
    <div className="screen-top-section bg-cover bg-bottom text-center">
      <text className="md:text-5xl text-3xl clip-text font-semibold text-white pb-4">
        {text}
      </text>
      <div className="justify-center flex">
        <div
          className="flex bg-gray-800 justify-center mt-2 w-fit p-3 pl-6 pr-6 bg-opacity-60"
          style={{ borderRadius: 24 }}
        >
          {navigationStack.map((item, index) => {
            return (
              <div
                key={index}
                className={`screen-top-section-navigation-item`}
                onClick={item.onClick}
              >
                <text
                  className={`font-medium ${
                    index === 0 ? "text-orange-500" : "text-white"
                  }`}
                >
                  {item.text}
                </text>
                {index !== navigationStack.length - 1 && (
                  <text className={`text-white font-medium ml-2 mr-2`}>
                    {">"}
                  </text>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScreenTopSection;
