import React, { useEffect, useMemo } from "react";
import "../../App.css";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { useNavigate } from "react-router-dom";
import { ServiceCard } from "../../components/simpleComponents";
import { ServicesType } from "../../utils/types";

const ServicesScreen: React.FC = () => {
  const navigate = useNavigate();

  const [services, setServices] = React.useState<{
    [category: string]: {
      categoryName: string;
      taggedServices: ServicesType[];
    };
  }>({});
  const [isApiLoading, setApiLoading] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState<string>("");

  const categoryIds = useMemo(() => {
    return Object.keys(services);
  }, [services]);

  const fetchServices = async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}service/web/list?limit=10000`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        const services: {
          [category: string]: {
            categoryName: string;
            taggedServices: ServicesType[];
          };
        } = {};
        data.res.result.forEach((service: ServicesType) => {
          if (services[service.category.toLowerCase()]) {
            services[service.category.toLowerCase()].taggedServices.push(
              service
            );
          } else {
            services[service.category.toLowerCase()] = {
              categoryName: service.category,
              taggedServices: [service],
            };
          }
        });
        setServices(services);
        setSelectedCategory(Object.keys(services)[0] || "");
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const renderCategoryTab = useMemo(() => {
    return (
      <div className="flex-row flex justify-center items-center mt-12 mb-12 overflow-x-scroll">
        {categoryIds.map((categoryId) => {
          const isSelected = categoryId === selectedCategory;
          return (
            <div
              onClick={() => setSelectedCategory(categoryId)}
              className={`sm:p-4 sm:pl-8 sm:pr-8 p-4 border-b-4 sm:rounded-lg rounded-md ${
                isSelected
                  ? "border-orange-600 shadow-lg"
                  : "border-transparent"
              }`}
            >
              <text
                className={`sm:text-2xl font-serif ${
                  isSelected ? "text-orange-600" : "text-black"
                }`}
              >
                {services[categoryId].categoryName}
              </text>
            </div>
          );
        })}
      </div>
    );
  }, [selectedCategory, services, categoryIds]);

  const renderServicesTaggedInSelectedCategory = useMemo(() => {
    return (
      <div className="sm:flex sm:flex-wrap pl-12 pr-12">
        {services[selectedCategory]?.taggedServices.map((service) => {
          return (
            <div
              className={`mb-12 ${
                services[selectedCategory]?.taggedServices.length === 1
                  ? "w-full"
                  : `lg:w-1/3 sm:w-1/2 w-full`
              }`}
              key={service._id}
            >
              <ServiceCard serviceInfo={service} />
            </div>
          );
        })}
      </div>
    );
  }, [selectedCategory, services]);

  return (
    <div className="Contact">
      <ScreenHeader selectedSectionId="services" />
      <ScreenTopSection
        text="Services"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Services", onClick: () => navigate("/services") },
        ]}
      />
      {isApiLoading ? (
        <div className="flex justify-center">
          <div className="loading-spinner" />{" "}
        </div>
      ) : Object.keys(services || {}).length > 0 ? (
        <div>
          {renderCategoryTab}
          {renderServicesTaggedInSelectedCategory}
        </div>
      ) : (
        <div className="pt-16 pb-32 pl-4 pr-8 text-center">
          <div>
            <text className="md:text-4xl text-2xl font-serif">
              No Services Available!
              <br />{" "}
              <text className="md:text-xl text-lg mt-2">
                Please Check Later
              </text>
            </text>
          </div>
          <button
            className="mt-8 bg-orange-600 p-4 pl-8 pr-8 rounded-md text-white font-bold hover:bg-orange-100 hover:text-orange-600"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact Us
          </button>
        </div>
      )}
      <ScreenFooter />
    </div>
  );
};

export default ServicesScreen;
