import React from "react";
import "../../App.css";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { useNavigate } from "react-router-dom";
import { BlogCard } from "../../components/simpleComponents";

const BlogScreen: React.FC = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = React.useState<
    { title: string; desc: string; _id: string; createdAt: string }[]
  >([]);
  const [isApiLoading, setIsApiLoading] = React.useState(true);

  const fetchBlogs = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}blog/web/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        setBlogs(data.res.result);
        setIsApiLoading(false);
      })
      .catch((err) => {
        setIsApiLoading(false);
      });
  };

  React.useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div>
      <ScreenHeader selectedSectionId="blog" />
      <ScreenTopSection
        text="Blogs"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Blog", onClick: () => navigate("/blogs") },
        ]}
      />
      <div className="md:pt-16 pt-8 pr-4 pl-4">
        {isApiLoading ? (
          <div className="flex justify-center">
            <div className="loading-spinner" />{" "}
          </div>
        ) : (
          <div className="md:flex md:flex-wrap justify-center">
            {blogs.length ? (
              blogs.map((blog) => (
                <div
                  className={`mb-12 ${
                    blogs.length === 1 ? "w-full" : `md:w-1/3 w-full`
                  }`}
                  key={blog._id}
                >
                  <BlogCard blogInfo={blog} />
                </div>
              ))
            ) : (
              <div className="pt-16 pb-32 pl-4 pr-8 text-center">
                <div>
                  <text className="md:text-4xl text-2xl font-serif">
                    No Services Available!
                    <br />{" "}
                    <text className="md:text-xl text-lg mt-2">
                      Please Check Later
                    </text>
                  </text>
                </div>
                <button
                  className="mt-8 bg-orange-600 p-4 pl-8 pr-8 rounded-md text-white font-bold hover:bg-orange-100 hover:text-orange-600"
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Contact Us
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <ScreenFooter />
    </div>
  );
};

export default BlogScreen;
