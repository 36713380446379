import React from "react";
import { ServicesType } from "../../utils/types";
import "./simpleComponents.css";
import {
  consultationLogo,
  consultationLogoWhite,
  ritualsLogo,
  ritualsLogoWhite,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";

type Props = {
  serviceInfo: ServicesType;
};

const ServiceCard: React.FC<Props> = ({ serviceInfo }) => {
  const navigate = useNavigate();

  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  return (
    <div
      className={`ml-4 mr-4 items-center align flex flex-col service-card p-8 h-full ${
        isMouseOver ? "shadow-2xl rounded-2xl" : "shadow-lg rounded-md"
      }`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        navigate(`/services/${serviceInfo._id}`, { state: { serviceInfo } });
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }}
    >
      <img
        src={
          serviceInfo.category === "Consultation"
            ? isMouseOver
              ? consultationLogoWhite
              : consultationLogo
            : require(`../../assets/images/Rituals/${serviceInfo.code}.jpeg`)
        }
        alt="App Logo"
        className={
          serviceInfo.category === "Consultation"
            ? "sm:w-44 w-32"
            : "aspect-video rounded-md"
        }
      />
      <text
        className={`text-lg font-semibold font-serif text-center mt-4 ${
          isMouseOver ? "text-white" : "text-black"
        }`}
      >
        {serviceInfo.name}
      </text>
      <button
        className={`mt-4 text-base border-b-2 border-transparent ${
          isMouseOver ? "text-white hover:border-white pr-2 pl-2" : ""
        }`}
      >
        Know More
      </button>
    </div>
  );
};

export default ServiceCard;
