import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import {
  AboutScreen,
  BlogScreen,
  HomeScreen,
  ContactScreen,
  ServicesScreen,
  SingleBlogScreen,
  SingleServiceScreen,
  UserRegistration,
  UserAccount,
  TermsAndConditions,
  PrivacyPolicy,
} from "../screens";
import Cookies from "js-cookie";

const RootNavigation = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/*" element={<Navigate to={"/"} />} />
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/blogs" element={<BlogScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/services" element={<ServicesScreen />} />
        <Route
          path="/account"
          element={(() => {
            const data = Cookies.get("jwt-token");
            if (data) {
              return <UserAccount />;
            }
            return <Navigate to={"/account/register"} />;
          })()}
        />
        <Route
          path="/account/register"
          element={(() => {
            const data = Cookies.get("jwt-token");
            if (!data) {
              return <UserRegistration />;
            }
            return <Navigate to={"/account"} />;
          })()}
        />
        <Route path="/blogs/:blogId" element={<SingleBlogScreen />} />
        <Route path="/services/:serviceId" element={<SingleServiceScreen />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default RootNavigation;
