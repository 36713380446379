import React, { useCallback, useEffect } from "react";
import { appLogo } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

type Props = {
  isVisible: boolean;
  paymentInfo: {
    razorpay_order_id: string;
    razorpay_payment_id: string;
    razorpay_signature: string;
    price: {
      amount: number;
      currency: string;
    };
  };
  serviceAvailedInfo: {
    category: string;
    name: string;
    code: string;
    desc: string;
  };
  onClose: () => void;
};

const MAX_ATTEMPTS_TO_CHECK_PAYMENT_STATUS = 10;
let checkPaymentStatusIntervalRef: any;

const PaymentSuccessPopup: React.FC<Props> = ({
  isVisible,
  paymentInfo,
  serviceAvailedInfo,
  onClose,
}) => {
  const navigate = useNavigate();

  const [paymentStatus, setPaymentStatus] = React.useState<
    "success" | "failed" | "pending"
  >("pending");
  const [isApiLoading, setApiLoading] = React.useState<boolean>(true);

  const remainingAttemptsToCheckPaymentStatus = React.useRef<number>(
    MAX_ATTEMPTS_TO_CHECK_PAYMENT_STATUS
  );

  const checkPaymentStatus = useCallback(() => {
    const userInfo = JSON.parse(Cookies.get("userInfo") || "{}");
    fetch(`${process.env.REACT_APP_BASE_URL}order/checkStatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
      body: JSON.stringify({
        orderId: paymentInfo.razorpay_order_id,
        paymentId: paymentInfo.razorpay_payment_id,
        price: paymentInfo.price,
        serviceAvailedInfo,
        userId: userInfo._id,
        userInfo: userInfo,
        signature: paymentInfo.razorpay_signature,
        env: process.env.REACT_APP_ENV,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.status === 1 && data.res.status === "paid") {
          setApiLoading(false);
          setPaymentStatus("success");
          clearInterval(checkPaymentStatusIntervalRef);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [paymentInfo, serviceAvailedInfo]);

  useEffect(() => {
    checkPaymentStatusIntervalRef = setInterval(() => {
      if (remainingAttemptsToCheckPaymentStatus.current > 0) {
        checkPaymentStatus();
        remainingAttemptsToCheckPaymentStatus.current -= 1;
      } else {
        setApiLoading(false);
        clearInterval(checkPaymentStatusIntervalRef);
      }
    }, 3000);

    return () => {
      clearInterval(checkPaymentStatusIntervalRef);
    };
  });

  const navigateToPurchaseHistory = () => {
    navigate("/account");
  };

  const onPressContactUs = () => {
    // TODO: on press contact us
  };

  const renderPaymentSuccessPopup = () => {
    return (
      <div
        className={`bg-white flex flex-col p-8 pb-12 sm:w-2/3 md:w-1/2 w-3/4 ${
          window.innerWidth > 600
            ? "popup rounded-lg"
            : "bottom-popup rounded-t-lg"
        }`}
      >
        <img src={appLogo} className="w-64 self-center" alt="appLogo" />
        {isApiLoading ? (
          <div className="flex justify-center items-center">
            <div className="justify-center items-center align-middle">
              <div className="loading-spinner" />
              <div className="text-center">
                <text className="text-center mt-4 text-base font-medium">
                  Check Payment Status...
                </text>
              </div>
              <div className="text-center">
                <text className="text-center mt-2 text-gray-500">
                  Please don't close this window
                </text>
              </div>
            </div>
          </div>
        ) : (
          <>
            {paymentStatus === "success" ? (
              <>
                <div className="justify-center items-center text-center mt-12">
                  <text className="md:text-2xl sm:text-xl text-lg font-semibold font-serif">
                    *Payment Success*
                  </text>
                </div>
                <button
                  className={`mt-8 p-2 ${
                    isApiLoading ? "bg-gray-500" : "bg-orange-600"
                  } rounded-md hover:rounded-sm text-white hover:${
                    isApiLoading ? "bg-slate-500" : "bg-orange-700"
                  } shadow-lg font-semibold`}
                  onClick={navigateToPurchaseHistory}
                  disabled={isApiLoading}
                >
                  Go to Purchase History
                </button>
              </>
            ) : (
              <>
                <div className="justify-center items-center text-center mt-12">
                  <text className="md:text-xl sm:text-lg text-base font-semibold font-serif">
                    Could not verify payment status. Please check later or
                    contact us!
                  </text>
                  <br />
                  <div className="mt-2">
                    <text className="sm:text-sm md:text-base text-xs">
                      {"Payment Id: " + paymentInfo.razorpay_payment_id}
                    </text>
                  </div>
                </div>
                <button
                  onClick={onPressContactUs}
                  className={`mt-8 p-2 bg-orange-600 rounded-md hover:rounded-sm text-white hover:bg-orange-700 shadow-lg font-semibold`}
                >
                  Contact Us
                </button>
              </>
            )}
          </>
        )}
        <button
          className={`mt-4 p-2 ${
            isApiLoading ? "bg-gray-400" : "bg-orange-600"
          } rounded-md hover:rounded-sm text-white hover:${
            isApiLoading ? "bg-slate-500" : "bg-orange-700"
          } shadow-lg font-semibold`}
          onClick={onClose}
          disabled={isApiLoading}
        >
          Okay, Got it
        </button>
      </div>
    );
  };

  const renderOverlay = () => {
    return <div className="overlay"></div>;
  };

  return (
    <>
      {isVisible && renderPaymentSuccessPopup()}
      {isVisible && renderOverlay()}
    </>
  );
};

export default PaymentSuccessPopup;
