import React, { useCallback, useMemo, useRef } from "react";
import "../../App.css";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { useNavigate } from "react-router-dom";
import { call, address } from "../../assets/svg";
import { sendMail, validateEmailAddress } from "../../utils/utilityMethods";

const ContactScreen: React.FC = () => {
  const navigate = useNavigate();

  const formDetails = useRef<{
    name?: string;
    email?: string;
    message?: string;
  }>({});
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);

  const validateDataAndSendEmail = useCallback(() => {
    if (
      formDetails.current.name &&
      formDetails.current.email &&
      formDetails.current.message
    ) {
      if (formDetails.current.name.length < 3) {
        alert("Name should be atleast 3 characters");
        return;
      } else if (!validateEmailAddress(formDetails.current.email)) {
        alert("Please enter valid email address");
        return;
      } else if (formDetails.current.message.length < 15) {
        alert("Message should be atleast 15 characters");
        return;
      }
      sendMail(
        {
          name: formDetails.current.name,
          email: formDetails.current.email,
          message: formDetails.current.message,
        },
        () => {
          formDetails.current = {};
          nameInputRef.current!.value = "";
          emailInputRef.current!.value = "";
          messageInputRef.current!.value = "";
        }
      );
    } else {
      alert("Please fill complete form");
    }
  }, []);

  const onChangeValue = useCallback((fieldId: string, value: string) => {
    formDetails.current = {
      ...formDetails.current,
      [fieldId]: value,
    };
  }, []);

  const renderInput = useCallback(
    ({
      id,
      label,
      type,
      inputRef,
    }: {
      id: string;
      label: string;
      type: "input" | "textarea";
      inputRef: React.RefObject<HTMLInputElement> | null;
    }) => {
      return (
        <div className="mt-8">
          <div className="mb-2">
            <text className="text-base font-semibold">{label}</text>
          </div>
          {type === "input" ? (
            <input
              id={id}
              ref={inputRef}
              className="w-full h-12 pl-4 pr-4 border-2 focus:rounded-sm focus:outline-none focus:border-orange-600 border-transparent"
              onChange={(e) => onChangeValue(id, e.target.value)}
            />
          ) : (
            <textarea
              rows={3}
              ref={inputRef as any}
              className="w-full pl-4 pr-4 pt-4 pb-4 border-2 focus:rounded-sm focus:outline-none focus:border-orange-600 border-transparent"
              onChange={(e) => onChangeValue(id, e.target.value)}
            />
          )}
        </div>
      );
    },
    [onChangeValue]
  );

  const renderContactUsForm = useMemo(() => {
    return (
      <div
        className="md:p-12 p-8"
        style={{ backgroundColor: "#F7F2FF", zIndex: 100000 }}
      >
        <text className="font-serif text-2xl">Have a Question?</text>
        {renderInput({
          id: "name",
          label: "Full Name",
          type: "input",
          inputRef: nameInputRef,
        })}
        {renderInput({
          id: "email",
          label: "Email",
          type: "input",
          inputRef: emailInputRef,
        })}
        {renderInput({
          id: "message",
          label: "Message",
          type: "textarea",
          inputRef: messageInputRef,
        })}
        <button
          className="w-full mt-8 bg-orange-500 p-3 text-white font-semibold uppercase"
          onClick={validateDataAndSendEmail}
        >
          Submit
        </button>
      </div>
    );
  }, [renderInput, validateDataAndSendEmail]);

  return (
    <div className="Contact">
      <ScreenHeader selectedSectionId="contact" />
      <ScreenTopSection
        text="Contact Us"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Contact Us", onClick: () => navigate("/contact") },
        ]}
      />
      <div className="md:flex md:flex-wrap md:pl-16 md:pr-16 pl-8 pr-8 md:pt-16 pt-8 md:pb-16 pb-8 ">
        <div className="md:w-3/5 mb-16">
          <div>
            <div className="mr-12">
              <text className="text-3xl font-serif">Contact Information</text>
              <div className="h-1 bg-orange-500 w-1/2 mt-2 mb-2" />
              <text className="font-light" style={{ fontSize: 14 }}>
                {window.innerWidth > 700
                  ? "Let's Connect and Explore the Cosmos Together: Reach Out to Us for Personalized Astrological Guidance and Support."
                  : "Reach Out to Us for Personalized Astrological Guidance and Support."}
              </text>
            </div>
            <div className="flex flex-wrap">
              <div className="pt-8 pb-8 mr-8 flex">
                <div className="border-dotted rounded-full border-2 border-orange-200 h-16 w-16  justify-center items-center flex border-animation">
                  <div className="bg-orange-500 h-12 w-12 rounded-full  justify-center items-center flex">
                    <img src={call} alt="Call Icon" className="h-5" />
                  </div>
                </div>
                <div className="ml-4">
                  <div className="mb-2">
                    <text className="text-orange-500 text-lg font-serif">
                      Call Us
                    </text>
                  </div>
                  <div>
                    <text className="text-gray-600 text-sm">
                      +(91)8074452083
                    </text>
                  </div>
                  <div>
                    <text className="text-gray-600 mt-2 text-sm">
                      +(91)9703334736
                    </text>
                  </div>
                </div>
              </div>
              <div className="pt-8 pb-8 flex">
                <div className="border-dotted rounded-full border-2 border-orange-200 h-16 w-16  justify-center items-center flex border-animation">
                  <div className="bg-orange-500 h-12 w-12 rounded-full  justify-center items-center flex">
                    <img src={address} alt="Tele Icon" className="h-5" />
                  </div>
                </div>
                <div className="ml-4">
                  <div className="mb-2">
                    <text className="text-orange-500 text-lg font-serif">
                      Mail Us
                    </text>
                  </div>
                  <div>
                    <text className="text-gray-600 text-sm">
                      dwarakaastrology@gmail.com
                    </text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-2/5">{renderContactUsForm}</div>
      </div>
      <ScreenFooter />
    </div>
  );
};

export default ContactScreen;
