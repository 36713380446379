import { EMAIL_ADDRESS } from "./constants";
import { ServicesType } from "./types";
import TIMEZONE_COUNTRY_CODE_JSON from "../utils/TIMEZONE_COUNTRY_CODE_JSON.json";

export const getProjectName = () => {
  return "Dwaraka Astrology";
}

export const sendMail = (details: {
  name: string,
  email: string,
  message: string,
}, clearForm?: () => void) => {
  const body = `Name: ${details.name}\nEmail: ${details.email}\n\nMessage: ${details.message}`
  const url = `https://mail.google.com/mail/?view=cm&to=${encodeURIComponent(EMAIL_ADDRESS)}&su=${encodeURIComponent("Query - Dwaraka Astrology")}&body=${encodeURIComponent(body)}`;
  window.open(url);
  clearForm?.();
}

export const sendMailToRequesService = (details: {
  userInfo: {
    name: string;
    email: string;
    phone: string;
  }
  serviceInfo: ServicesType;
},) => {
  const subject = `Request for Service - ${details.serviceInfo.name}`;
  const body = `
Dear Dwaraka Astrology,

I hope this email finds you well. I am writing to inquire about your services and request your assistance with ${details.serviceInfo.name} service (Service Code: ${details.serviceInfo.code}).

Allow me to provide you with my details and the specific service requirements:

Name: ${details.userInfo.name}
Email: ${details.userInfo.email}
Phone Number: ${details.userInfo.phone}

Service Details:
Service Name: ${details.serviceInfo.name}
Preferred Service Date: [PLEASE_SPECIFY_DATE]
Additional Requirements: [PLEASE_SPECIFY_ADDITIONAL_REQUIREMENTS]

Contact me at ${details.userInfo.phone} or ${details.userInfo.email} for further interaction.`
  const url = `https://mail.google.com/mail/?view=cm&to=${encodeURIComponent(EMAIL_ADDRESS)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.open(url);
}

export const validateEmailAddress = (email: string) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
}

export const validateIndianPhoneNumber = (phoneNumber: string) => {
  const indianPhoneNumberRegex = /^(\+91|0)?[6-9]\d{9}$/;
  console.log(indianPhoneNumberRegex.test(phoneNumber));
  
  return indianPhoneNumberRegex.test(phoneNumber); 
}

export const checkIfIndianUser = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  return TIMEZONE_COUNTRY_CODE_JSON[timeZone]?.includes("IN");
};
