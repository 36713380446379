import React from "react";
import { useNavigate } from "react-router-dom";
import { appLogoLight } from "../../assets/images";
import "./compoundComponents.css";
import { facebook, instagram, youtube } from "../../assets/svg";

const ScreenFooter: React.FC = () => {
  const navigate = useNavigate();

  const onPressFacebookIcon = () => {
    window.open("https://www.facebook.com/Dwarakaastrology/", "_blank");
  };

  const onPressInstagramIcon = () => {
    window.open(
      "https://instagram.com/dwarakaastrology?igshid=ZDdkNTZiNTM",
      "_blank"
    );
  };

  const onPressYoutubeIcon = () => {
    window.open("https://www.youtube.com/@saisateesh572/about", "_blank");
  };

  const quickLinks = [
    {
      id: "home",
      name: "Home",
      onClick: () => {
        navigate("/");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
    {
      id: "services",
      name: "Services",
      onClick: () => {
        navigate("/services");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
    {
      id: "about",
      name: "About Me",
      onClick: () => {
        navigate("/about");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
    {
      id: "contact",
      name: "Contact Us",
      onClick: () => {
        navigate("/contact");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
    {
      id: "blog",
      name: "Blogs",
      onClick: () => {
        navigate("/blogs");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
    {
      id: "privacyPolicy",
      name: "Privacy Policy",
      onClick: () => {
        navigate("/privacyPolicy");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
    {
      id: "termsAndConditions",
      name: "Terms & Conditions",
      onClick: () => {
        navigate("/termsAndConditions");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      },
    },
  ];

  const contactInfo = [
    {
      id: "address",
      value: "Hyderabad, Telangana",
    },
    {
      id: "email",
      value: "dwarakaastrology@gmail.com",
    },
    {
      id: "number",
      value: `+(91)8074452083/+(91)9703334736`,
    },
  ];

  return (
    <div className="footer divide-dashed">
      <div className="md:flex self-end md:p-16 sm:p-8 p-4 justify-around flex-wrap">
        <div className="text-center md:text-left md:w-1/2 lg:w-2/6 mb-12 sm:mb-8">
          <div className="md:justify-start justify-center flex sm:mt-0 mt-8">
            <img src={appLogoLight} alt="App Logo" className="sm:w-64 w-48" />
          </div>
          <div className="mt-6 md:mr-16 lg:mr-0 sm:ml-0 sm:mr-0 mr-4 ml-4">
            <text className="text-white" style={{ fontSize: 12 }}>
              <b>Disclaimer: </b>Astrology is an ancient art and science that
              can provide insights and guidance. Our astrology services are
              based on the positions and movements of celestial bodies, which
              are subject to change and interpretation. By using our website or
              services, you acknowledge and accept that astrology is a tool for
              self-discovery and personal growth, but it is not a guarantee of
              future events or outcomes.
            </text>
          </div>
          <div className="mt-4">
            <text className="text-white font-semibold font-serif text">
              Follow Us On:
            </text>
            <div className="flex mt-2 justify-center md:justify-start">
              <div
                className="rounded-full bg-white hover:bg-orange-500 justify-center items-center flex mr-4"
                onClick={onPressFacebookIcon}
              >
                <img src={facebook} alt="facebook" className="w-2/3" />
              </div>
              <div
                className="rounded-full bg-white hover:bg-orange-500 justify-center items-center flex mr-4"
                onClick={onPressInstagramIcon}
              >
                <img src={instagram} alt="instagram" className="w-2/3" />
              </div>
              <div
                className="rounded-full bg-white hover:bg-orange-500 justify-center items-center flex mr-4"
                onClick={onPressYoutubeIcon}
              >
                <img src={youtube} alt="youtube" className="w-2/3" />
              </div>
            </div>
          </div>
        </div>
        <div className="text-left md:w-1/2 lg:w-2/6 mb-12 sm:mb-8">
          <div>
            <text
              className="text-white font-semibold border-b-orange-600 border-b-2 pb-1 rounded-sm"
              style={{ fontSize: 20 }}
            >
              Quick Links
            </text>
            <div className="mt-6">
              {quickLinks.map((quickLink) => {
                return (
                  <div key={quickLink.id} onClick={quickLink.onClick}>
                    <text className="text-white">{quickLink.name}</text>
                    <div
                      className="bg-gray-500 mt-2 mb-2"
                      style={{ height: 0.5 }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="text-left md:w-1/2 lg:w-1/6 mb-12 sm:mb-8">
          <div>
            <text
              className="text-white font-semibold border-b-orange-600 border-b-2 pb-1 rounded-sm"
              style={{ fontSize: 20 }}
            >
              Contact Info
            </text>
            <div className="mt-6">
              {contactInfo.map((contactInfo) => {
                return (
                  <div key={contactInfo.id} className="mb-4">
                    <text className="text-white" style={{ fontSize: 16 }}>
                      {contactInfo.value}
                    </text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-black justify-center align-middle text-center p-2">
        <text className="text-white font-extralight" style={{ fontSize: 14 }}>
          <b>Copyright © 2023 Dwaraka Astrology || All Right Reserved</b>
        </text>
      </div>
    </div>
  );
};

export default ScreenFooter;
