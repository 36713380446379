import React from "react";
import { underlineImage } from "../../assets/images";
import { BlogCard } from "../simpleComponents";
import { useNavigate } from "react-router-dom";

const BlogsSection: React.FC = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = React.useState<
    { title: string; desc: string; _id: string; createdAt: string }[]
  >([]);
  const [isApiLoading, setIsApiLoading] = React.useState(true);

  const fetchBlogs = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}blog/web/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        setBlogs(data.res.result);
        setIsApiLoading(false);
      })
      .catch((err) => {
        setIsApiLoading(false);
      });
  };

  React.useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div className="blog-container md:pl-48 md:pr-48 lg:pl-68 lg:pr-68 pl-8 pr-8 md:pt-16 md:pb-8 pt-8 pb-8">
      <div className="text-center md:pl-24 md:pr-24 sm:pl-8 sm:pr-8">
        <text className="font-serif" style={{ fontSize: 32 }}>
          Latest Blogs
        </text>
        <div className="mb-4 flex justify-center">
          <img src={underlineImage} alt="Underline" />
        </div>
        <text className="font-light" style={{ fontSize: 14 }}>
          Unlocking the Mysteries of the Stars: Discovering the Power of
          Astrology.
        </text>
      </div>
      {isApiLoading ? (
        <div className="flex justify-center">
          <div className="loading-spinner" />{" "}
        </div>
      ) : (
        <>
          <div className="md:mt-12 mt-8 md:flex md:flex-wrap justify-center">
            {blogs.length ? (
              blogs.slice(0, 3).map((blog) => (
                <div
                  className={`mb-12 ${
                    blogs.length === 1
                      ? "w-full"
                      : `md:w-1/${Math.min(blogs.length, 3)} w-full`
                  }`}
                  key={blog._id}
                >
                  <BlogCard blogInfo={blog} />
                </div>
              ))
            ) : (
              <div className="pb-8">
                <text className="md:text-4xl text-2xl font-serif text-orange-500">
                  No Blogs Available!
                  <br />{" "}
                  <text className="md:text-xl text-lg mt-2">
                    Please Check Later
                  </text>
                </text>
              </div>
            )}
          </div>
          {blogs.length > 3 && (
            <div className="md:text-right" onClick={() => navigate("/blogs")}>
              <text className="bg-orange-500 p-2 text-white mr-4">
                {"View More >>"}
              </text>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BlogsSection;
