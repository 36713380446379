import React, { useCallback, useMemo, useRef } from "react";
import {
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { useNavigate } from "react-router-dom";
import {
  validateEmailAddress,
  validateIndianPhoneNumber,
} from "../../utils/utilityMethods";

const UserRegistration: React.FC = () => {
  const navigate = useNavigate();

  const formDetails = useRef<{
    name?: string;
    email?: string;
    phone?: string;
    password?: string;
  }>({});
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const registerUser = useCallback(async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
      body: JSON.stringify({
        name: formDetails.current.name,
        email: formDetails.current.email,
        phone: formDetails.current.phone,
        password: formDetails.current.password,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          alert("User registered successfully");
          navigate("/");
        } else if (data.err.errCode === 409) {
          alert(
            "User already exists with this email address! Contact us for more details"
          );
          passwordInputRef.current!.value = "";
        }
      })
      .catch((err) => {
        alert("Something went wrong! Please try again");
        passwordInputRef.current!.value = "";
      });
  }, [navigate]);

  const validateDataAndRegisterUser = useCallback(() => {
    if (
      formDetails.current.name &&
      formDetails.current.email &&
      formDetails.current.phone &&
      formDetails.current.password
    ) {
      if (formDetails.current.name.length < 3) {
        alert("Name should be atleast 3 characters");
        return;
      } else if (!validateEmailAddress(formDetails.current.email)) {
        alert("Please enter valid email address");
        return;
      } else if (
        formDetails.current.phone.length < 10 ||
        !validateIndianPhoneNumber(formDetails.current.phone)
      ) {
        alert(
          "Please use a valid Phone Number! We will use this to contact you"
        );
        return;
      } else if (formDetails.current.password.length < 5) {
        alert("Please use a strong password");
        return;
      }
      registerUser();
    } else {
      alert("Please fill complete form");
    }
  }, [registerUser]);

  const onChangeValue = useCallback((fieldId: string, value: string) => {
    formDetails.current = {
      ...formDetails.current,
      [fieldId]: value,
    };
  }, []);

  const renderInput = useCallback(
    ({
      id,
      label,
      type,
      inputRef,
    }: {
      id: string;
      label: string;
      type: "input" | "password";
      inputRef: React.RefObject<HTMLInputElement> | null;
    }) => {
      return (
        <div className="mt-8">
          <div className="mb-2">
            <text className="text-base font-semibold">{label}</text>
          </div>
          <input
            id={id}
            ref={inputRef}
            type={type}
            className="w-full h-12 pl-4 pr-4 border-2 focus:rounded-sm focus:outline-none focus:border-orange-600 border-transparent"
            onChange={(e) => onChangeValue(id, e.target.value)}
          />
        </div>
      );
    },
    [onChangeValue]
  );

  const renderRegistrationForm = useMemo(() => {
    return (
      <div
        className="md:p-12 p-8 sm:m-16 m-4 mt-8 mb-8"
        style={{ backgroundColor: "#F7F2FF", zIndex: 100000 }}
      >
        <text className="font-serif text-2xl">Register Yourself</text>
        {renderInput({
          id: "name",
          label: "Full Name",
          type: "input",
          inputRef: nameInputRef,
        })}
        {renderInput({
          id: "phone",
          label: "Phone Number",
          type: "input",
          inputRef: phoneNumberRef,
        })}
        {renderInput({
          id: "email",
          label: "Email",
          type: "input",
          inputRef: emailInputRef,
        })}
        {renderInput({
          id: "password",
          label: "Password",
          type: "password",
          inputRef: passwordInputRef,
        })}
        <button
          className="w-full mt-8 bg-orange-500 p-3 text-white font-semibold uppercase"
          onClick={validateDataAndRegisterUser}
        >
          Register
        </button>
      </div>
    );
  }, [renderInput, validateDataAndRegisterUser]);
  return (
    <>
      <ScreenHeader selectedSectionId="account" />
      <ScreenTopSection
        text="Register Yourself"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Register", onClick: () => null },
        ]}
      />
      {renderRegistrationForm}
    </>
  );
};

export default UserRegistration;
