import React from "react";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";
import { Link, useNavigate } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ScreenHeader />
      <ScreenTopSection
        text="Privacy Policy"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          { text: "Privacy Policy", onClick: () => navigate("/privacyPolicy") },
        ]}
      />
      <div className="lg:p-32 md:p-24 p-8 md:pb-48 pb-24 lg:mr-32 md:mr-16">
        <text className="font-light font-serif tracking-wider">
          <text className="text-xl font-semibold font-serif tracking-normal">
            Last updated:{" "}
            <text className="text-lg text-gray-700 underline">
              10th August, 2023
            </text>
          </text>
          <br />
          <br />
          Welcome to <text className="font-semibold">DWARAKA ASTROLOGY</text>.
          We are committed to protecting your privacy and ensuring the security
          of your personal information. This Privacy Policy outlines how we
          collect, use, disclose, and protect the information you provide when
          using our website and services. By accessing or using{" "}
          <Link
            to={"www.dwarakaastrology.com"}
            className="underline text-blue-500"
          >
            www.dwarakaastrology.com
          </Link>
          , you agree to the practices described in this policy.
          <br />
          <br />
          <br />
          <text className="text-2xl font-semibold">
            Information We Collect:
          </text>
          <br />
          <br />
          <div>
            <text className="font-semibold">1. Personal Information:</text> When
            you sign up for our services, we collect personal information such
            as your name, email address, and password. This information is
            crucial to create and manage your account, grant you access to our
            services, and facilitate communication. We ensure that your password
            is securely encrypted to prevent unauthorized access. <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">2. Payment Information: </text>
            To process payments for our services, we use payment gateways which
            collect payment-related information, including credit/debit card
            details, billing address, and transaction history. We prioritize the
            security of your financial information and use encrypted payment
            gateways to guarantee its safety.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">3. Usage Information:</text> We
            gather data about your interactions with our website and services,
            such as the pages you visit, the features you use, and the actions
            you take. This information aids us in improving our website's
            functionality and enhancing your overall user experience.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">4. Communications:</text> When
            you contact us via email or through our "Contact Us" section, we
            collect the information you provide to assist you with your
            inquiries and respond to your messages promptly. This helps us
            address your concerns effectively.
            <br />
          </div>
          <br />
          <br />
          <text className="text-2xl font-semibold">
            How We Use Your Information
          </text>
          <br />
          <br />
          <div>
            <text className="font-semibold">1. Account Management: </text> Your
            personal information is used to create and manage your account,
            process transactions, and provide access to the services you've
            purchased. This ensures a seamless experience while using our
            platform.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">2. Communication:</text> We may
            use your contact information to send you important updates,
            notifications, and promotional materials related to our services.
            However, you have the option to opt out of receiving promotional
            communications at any time.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">
              3. Improvement of Services:
            </text>{" "}
            Your usage data aids us in analyzing trends, identifying areas for
            improvement, and enhancing the functionality of our website and
            services. This enables us to continually enhance your experience on
            our platform.
            <br />{" "}
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">4. Customer Support:</text> The
            information you provide when contacting us enables us to assist you
            with your inquiries, feedback, and concerns effectively. We strive
            to provide you with timely and accurate support.
            <br />{" "}
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">5. Legal Requirements:</text>{" "}
            In certain situations, we may need to disclose your information to
            comply with legal obligations, enforce our terms of service, protect
            our rights, or respond to lawful requests from government
            authorities.
            <br />
          </div>
          <br />
          <br />
          <text className="text-xl font-semibold">Data Security</text>
          <br />
          <br />
          We employ industry-standard security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no method of transmission over the internet or
          electronic storage is 100% secure. We cannot guarantee absolute
          security, but we strive to implement and maintain reasonable
          safeguards.
          <br />
          <br />
          <br />
          <text className="text-2xl font-semibold">Your Choices</text>
          <br />
          <br />
          <div>
            <text className="font-semibold">1. Account Management:</text> You
            can access and update your personal information in your account
            settings. If you wish to delete your account, please contact us at
            [dwarakaastrology@gmail.com].
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">
              2. Communication Preferences:
            </text>{" "}
            You can choose to unsubscribe from promotional emails by following
            the instructions provided in the email or by contacting us.
            <br />
          </div>
          <br />
          <br />
          <text className="text-xl font-semibold">Contact Us</text>
          <br />
          <br /> If you have any questions, concerns, or requests regarding your
          personal information or this Privacy Policy, please contact us at
          [dwarakaastrology@gmail.com].
          <br />
          <br />
          <text className="text-xl font-semibold">Changes to this Policy</text>
          <br />
          <br />
          We reserve the right to modify this Privacy Policy at any time.
          Changes will be effective upon posting on this page. We encourage you
          to review this policy periodically for updates. By using our website
          and services, you acknowledge that you have read and understood this
          Privacy Policy and consent to the practices described herein.
        </text>
      </div>
      <ScreenFooter />
    </div>
  );
};

export default PrivacyPolicy;
