import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ScreenFooter,
  ScreenHeader,
  ScreenTopSection,
} from "../../components/compoundComponents";

const TermsAndConditions: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ScreenHeader />
      <ScreenTopSection
        text="Terms And Conditions"
        navigationStack={[
          { text: "Home", onClick: () => navigate("/") },
          {
            text: "Terms And Conditions",
            onClick: () => navigate("/termsAndConditions"),
          },
        ]}
      />
      <div className="lg:p-32 md:p-24 p-8 md:pb-48 pb-24 lg:mr-32 md:mr-16">
        <text className="font-light font-serif tracking-wider">
          <text className="text-xl font-semibold font-serif tracking-normal">
            Last updated:{" "}
            <text className="text-lg text-gray-700 underline">
              10th August, 2023
            </text>
          </text>
          <br />
          <br /> Welcome to{" "}
          <text className="font-semibold">DWARAKA ASTROLOGY</text>. These Terms
          and Conditions outline the rules and regulations governing your use of
          our website and services. By accessing or using{" "}
          <Link
            to={"www.dwarakaastrology.com"}
            className="underline text-blue-500"
          >
            www.dwarakaastrology.com
          </Link>
          , you agree to abide by these terms. Please read them carefully before
          using our platform.
          <br />
          <br />
          <br />
          <text className="text-2xl font-semibold">
            Refund and Cancellation Policy
          </text>
          <br />
          <br />
          <div>
            <text className="font-semibold">1. Services and Offerings:</text> We
            offer various astrology consultation services and rituals on our
            platform. These services are tailored to meet your specific needs
            and requirements.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">2. Cancellation Policy:</text>{" "}
            Once a service has been opted for and payment has been made,
            cancellations are generally not permitted. This is because our
            services are personalized and involve the dedicated time and effort.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">3. No Refunds:</text> We
            understand that circumstances may change, but due to the nature of
            our services, we have a strict no-refund policy. Once a service is
            availed and the payment has been processed, the transaction is
            considered final.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">
              4. Reasons for No Refund:
            </text>{" "}
            The no-refund policy is in place for several reasons:
            <br />
            <div className="ml-5 flex mt-2">
              <div className="rounded-full mr-2">
                <text className="font-semibold">a.</text>
              </div>
              <div>
                <text className="font-semibold">Customization:</text> Each
                service is tailored to your unique needs, which involves a
                significant amount of customization and effort on our part.
              </div>
            </div>
            <div className="ml-5 flex mt-2">
              <div className="rounded-full mr-2">
                <text className="font-semibold">b.</text>
              </div>
              <div>
                <text className="font-semibold">Instant Availability:</text> Our
                services are often accessible immediately upon payment, which
                means you gain instant access to valuable content and insights.
              </div>
            </div>
            <div className="ml-5 flex mt-2">
              <div className="rounded-full mr-2">
                <text className="font-semibold">c.</text>
              </div>
              <div>
                <text className="font-semibold">Intellectual Property:</text>{" "}
                Our services may include intellectual property, such as
                personalized reports, analysis, and insights, which are made
                available to you upon payment. Due to these factors, we are
                unable to offer refunds for services that have already been
                availed.
              </div>
            </div>
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">5. Quality Assurance:</text> We
            strive to ensure the highest quality in our offerings. If you
            encounter any issues with the services you've received, please
            contact us at [dwarakaastrology@gmail.com] to address your concerns.
            <br />
          </div>
          <br />
          <br />
          <text className="text-2xl font-semibold">General Terms</text>
          <br />
          <br />
          <div>
            <text className="font-semibold">1. Use of Services:</text> By using
            our services, you acknowledge that you have carefully reviewed and
            agreed to the terms and conditions presented herein.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">
              2. Account Responsibility:
            </text>{" "}
            You are responsible for maintaining the confidentiality of your
            account credentials and for all activities associated with your
            account.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">
              3. Intellectual Property:
            </text>{" "}
            All content and materials provided as part of our services are
            protected by copyright and other intellectual property laws. You
            agree not to reproduce, distribute, modify, or create derivative
            works based on our content without explicit consent.
            <br />
          </div>
          <div className="mt-3">
            <text className="font-semibold mt-8">4. Changes to Terms:</text> We
            reserve the right to modify these terms at any time. Changes will be
            effective upon posting on this page. Continued use of our platform
            after changes have been made signifies your acceptance of the
            updated terms.
            <br />
            <br />
            <br />
            <text className="text-xl font-semibold">Contact Us</text>
            <br />
            <br />
            If you have any questions or concerns about our Terms and
            Conditions, please contact us at [dwarakaastrology@gmail.com]. By
            using our website and services, you acknowledge that you have read
            and understood these Terms and Conditions and agree to comply with
            them.
            <br />
          </div>
        </text>
      </div>
      <ScreenFooter />
    </div>
  );
};

export default TermsAndConditions;
