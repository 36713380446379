import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountLogo, appLogo } from "../../assets/images";
import LoginPopup from "./LoginPopup";
import Cookies from "js-cookie";

type Props = {
  selectedSectionId?:
    | "home"
    | "about"
    | "services"
    | "blog"
    | "contact"
    | "account";
};

const ScreenHeader: React.FC<Props> = ({ selectedSectionId }) => {
  const navigate = useNavigate();
  const [showheaderRightIcon, setShowHeaderRightIcon] =
    useState<boolean>(false);
  const [openSideNavBar, setOpenSideNavBar] = useState<boolean>(false);
  const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false);

  const isLoggedIn = useMemo(() => {
    const authToken = Cookies.get("jwt-token");
    return !!authToken;
  }, []);

  const headerSections = useMemo(
    () => [
      {
        id: "home",
        sectionName: "Home",
        onClick: () => {
          navigate("/", { replace: true });
        },
      },
      {
        id: "about",
        sectionName: "About Me",
        onClick: () => {
          navigate("/about", { replace: true });
        },
      },
      {
        id: "services",
        sectionName: "Services",
        onClick: () => {
          navigate("/services", { replace: true });
        },
      },
      {
        id: "blog",
        sectionName: "Blogs",
        onClick: () => {
          navigate("/blogs", { replace: true });
        },
      },
      {
        id: "contact",
        sectionName: "Contact Us",
        onClick: () => {
          navigate("/contact", { replace: true });
        },
      },
    ],
    [navigate]
  );

  useEffect(() => {
    setShowHeaderRightIcon(window.innerWidth < 800);
    window.addEventListener("resize", () => {
      if (window.innerWidth < 800) {
        setShowHeaderRightIcon(true);
      } else {
        setShowHeaderRightIcon(false);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const sideNavBar = useMemo(() => {
    if (openSideNavBar) {
      return (
        <div className="fixed bg-white flex h-screen w-1/2 z-20">
          <div className="sidebar w-full h-full">
            <div>
              <img
                src={appLogo}
                alt="App Logo"
                className="sm:w-64 w-48 p-4 ml-2"
              />
              {headerSections.map((headerSection) => {
                const isSelected = selectedSectionId === headerSection.id;
                return (
                  <div
                    key={headerSection.id}
                    onClick={() => {
                      setOpenSideNavBar(false);
                      headerSection.onClick();
                    }}
                    className={`border-gray-200 p-4 hover:text-orange-600 text-center ${
                      isSelected && "text-orange-600"
                    }`}
                    style={{ borderWidth: 0.4 }}
                  >
                    <text className="font-semibold">
                      {headerSection.sectionName}
                    </text>
                  </div>
                );
              })}
            </div>
            <div className="flex">
              <div
                onClick={() => {
                  setOpenSideNavBar(false);
                  if (isLoggedIn) {
                    navigate("/account");
                  } else {
                    setOpenLoginPopUp(true);
                  }
                }}
                className={`border-gray-200 p-4 hover:text-orange-600 text-center w-full flex justify-center`}
                style={{ borderWidth: 0.4 }}
              >
                <img
                  src={accountLogo}
                  alt="accountLogo"
                  width={24}
                  className="mr-2"
                />
                <text className="font-semibold">
                  {isLoggedIn ? "My Profile" : "Log In"}
                </text>
              </div>
              {isLoggedIn && (
                <div
                  onClick={() => {
                    setOpenSideNavBar(false);
                    Cookies.remove("jwt-token");
                    Cookies.remove("userInfo");
                    navigate("/");
                    window.location.reload();
                  }}
                  className={`border-gray-200 p-4 hover:text-orange-600 text-center w-full flex justify-center`}
                  style={{ borderWidth: 0.4 }}
                >
                  <text className="font-semibold">{"Log Out"}</text>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [openSideNavBar, headerSections, selectedSectionId, isLoggedIn, navigate]);

  const renderContactInfoAndUserLogin = useMemo(() => {
    if (!showheaderRightIcon) {
      return (
        <div
          className="flex flex-1 justify-between p-3 pr-8 pl-8"
          style={{ backgroundColor: "#03051A" }}
        >
          <div>
            <></>
          </div>
          <div
            className="flex"
            onClick={() => {
              if (isLoggedIn) {
                navigate("/account");
              } else {
                setOpenLoginPopUp(true);
              }
            }}
          >
            <img
              src={accountLogo}
              alt="accountLogo"
              width={24}
              className="mr-2"
            />
            <text className="text-white hover:text-orange-600 font-semibold">
              {isLoggedIn ? "My Profile" : "Log In"}
            </text>
            {isLoggedIn && (
              <text
                className="text-white hover:text-orange-600 font-semibold ml-4"
                onClick={() => {
                  setOpenSideNavBar(false);
                  Cookies.remove("jwt-token");
                  Cookies.remove("userInfo");
                  navigate("/");
                  window.location.reload();
                }}
              >
                {"Log Out"}
              </text>
            )}
          </div>
        </div>
      );
    }
    return null;
  }, [showheaderRightIcon, isLoggedIn, navigate]);

  return (
    <>
      {sideNavBar}
      {renderContactInfoAndUserLogin}
      <LoginPopup
        isVisible={openLoginPopUp}
        onClose={() => setOpenLoginPopUp(false)}
      />
      <div className="flex">
        <img
          src={appLogo}
          alt="App Logo"
          className="sm:w-64 w-48  p-4 ml-2 object-contain"
          onClick={() => navigate("/")}
        />
        <div className="flex flex-1 justify-end">
          {showheaderRightIcon ? (
            <button
              onClick={() => {
                setOpenSideNavBar((prevVal) => !prevVal);
              }}
              className="bg-orange-600 w-12 h-12 rounded-full drop-shadow-lg justify-center items-center self-center mr-4 text-white hover:drop-shadow-2xl"
            >
              {openSideNavBar ? "\u2573" : "\u2630"}
            </button>
          ) : (
            <>
              {headerSections.map((headerSection) => {
                const isSelected = selectedSectionId === headerSection.id;
                return (
                  <div
                    key={headerSection.id}
                    onClick={headerSection.onClick}
                    className={`mr-8 ${
                      isSelected && "border-t-orange-600 text-orange-600"
                    } hover:border-t-orange-600 hover:text-orange-600 border-4 border-transparent flex`}
                  >
                    <div className="items-center flex justify-center">
                      <text className="font-semibold">
                        {headerSection.sectionName}
                      </text>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ScreenHeader;
